<template>
    <div>

        <div class=" loading-search" v-if="loading">
            <div class="fa-3x text-center">
                <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
            </div>
        </div>
        <!-- BEGIN breadcrumb -->
        <ol class="breadcrumb float-xl-end">
            <li class="breadcrumb-item"><a href="javascript:;">Reportes</a></li>
            <li class="breadcrumb-item"><a href="javascript:;">Lealtad</a></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Canjeados</a></li>
        </ol>

        <h1 class="page-header">Puntos Canjeados<small></small></h1>

        <form @submit.prevent="search">
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group row">

                        <div class="col-md-3">
                            <label class="col-form-label">Fecha inicial</label>
                            <div class="input-group">
                                <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
                                <label for="dateTimeInput" class="input-group-text">
                                    <i class="fa fa-calendar"></i>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-form-label">Fecha final</label>
                            <div class="input-group">
                                <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
                                <label for="dateTimeInputEnd" class="input-group-text">
                                    <i class="fa fa-calendar"></i>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-form-label">Ciudad</label>
                            <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect"
                                :disabled="deshabilitarCombos">
                                <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                                    {{ ciudad.Nombre }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-form-label">Estación</label>
                            <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
                                <option value="0">Todas</option>
                                <option v-for="estacion in estaciones" :value="estacion.NoEstacion"
                                    v-bind:key="estacion.NoEstacion">
                                    {{ estacion.Nombre }}
                                </option>
                            </select>
                        </div>

                    </div>
                </div>
                <div class="col-md-2">
                    <button type="submit" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">{{
                        textoBotonBuscar }}</button>
                </div>
            </div>
        </form>
        <div>
            <panel title="Listros Canjeados" bodyClass="p-0" class="" style="margin-top: 15px;">

                <DxDataGrid id="gridContainer" :data-source="searchResult" key-expr="Transaccion" :show-borders="true"
                    @exporting="onExporting" :show-column-lines="true" :show-sumary="true" :show-row-lines="true"
                    width="100%" :column-auto-width="true">

                    <DxScrolling column-rendering-mode="virtual" />

                    <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
                        :show-navigation-buttons="true" />

                    <DxPaging :page-size="10" :page-index="1" />
                    <DxFilterRow />

                    <DxHeaderFilter :visible="showHeaderFilter" />

                    <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

                    <DxGrouping :auto-expand-all="true" />
                    <DxExport :enabled="true" :allow-export-selected-data="false" />

                    <DxSelection mode="false" />

                    <DxColumn data-field="Estacion" caption="Estacion">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn data-field="Bomba" caption="Bomba">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn data-field="Transaccion" caption="Transacción">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn data-field="Fecha" caption="Fecha" dataType="datetime" format="dd/MM/yyyy" />

                    <DxColumn data-field="NoEmpleado" caption="No Empleado">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn data-field="Vendedor" caption="Vendedor">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn data-field="CardNumber" caption="CardNumber">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn data-field="Cliente" caption="Cliente">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>


                    <DxColumn data-field="Cantidad" caption="Cantidad" format="#,##0.##">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>
                    <DxColumn data-field="Producto" caption="Producto">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn data-field="PreviousBalance" caption="Previous Balance" format="#,##0.##">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn data-field="Balance" caption="Balance" format="#,##0.##">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn data-field="LitrosRedimidos" caption="Litros Redimidos" format="#,##0.##">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn data-field="Descuento" caption="Descuento" dataType="number">
                        <DxFormat type="currency" :precision="2" />
                    </DxColumn>

                    <DxColumn data-field="Total" caption="Total" dataType="number">
                        <DxFormat type="currency" :precision="2" />
                    </DxColumn>

                    <DxColumn data-field="Usuario" caption="Usuario">
                        <DxHeaderFilter :allow-search="true" />
                    </DxColumn>

                    <DxColumn dataField="Opciones" caption="Opciones" cell-template="grid-cell-template" :width="100"
                        :fixed="true" fixed-position="right" />

                    <template #grid-cell-template="{ data }">
                        <div class="text-center">
                            <div class="row">
                                <div class="col">
                                    <i class="btn btn-sm btn-success fas fa-file-alt btn-pointer"
                                        @click="openModalVisor(data)" alt="" width="20" height="20" title="Ticket"></i>
                                </div>
                            </div>
                        </div>
                    </template>


                    <DxSummary>
                        <DxTotalItem column="LitrosRedimidos" summary-type="sum" value-format="#,##0.##"
                            displayFormat='{0}'>
                        </DxTotalItem>
                        <DxTotalItem column="Descuento" summary-type="sum" value-format="$#,##0.##" displayFormat='{0}'>
                        </DxTotalItem>
                        <DxTotalItem column="Total" summary-type="sum" value-format="$#,##0.##" displayFormat='{0}'>
                        </DxTotalItem>
                    </DxSummary>

                </DxDataGrid>

            </panel>
        </div>
        <b-modal id="modalDialogVisor" size="xl" centered cancel-variant="default">
            <template slot="modal-header">
                <h4 class="modal-title">Visualizando Ticket</h4>
                <a class="btn-close" @click="$bvModal.hide('modalDialogVisor')"></a>
            </template>
            <div class="card">
                <object type="application/pdf" :data="Archivo.base64" width="100%" height="600px"></object>
            </div>

            <template #modal-footer>
                <div class="row">
                    <div class="col">
                        <b-button class="float-right" variant="primary" @click="$bvModal.hide('modalDialogVisor')">
                            Close
                        </b-button>
                    </div>
                </div>
            </template>

        </b-modal>
        <!-- bottom left vue-notification -->
        <notifications group="top-right" position="top right" :speed="1000" />
    </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';



import {
    DxDataGrid, DxFormat, DxTotalItem, DxSummary,
    DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
//import service from './data.js';


var numeral = require("numeral");
import Vue from "vue";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,00.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("currency", function (value) {
    return numeral(value).format("$0,0.00");
});


export default {
    mounted: function () {
        var self = this;

        var userSetting = JSON.parse(localStorage.getItem('userSettings'));
        this.usuario = userSetting.Usuario;
        this.usuarioEstacion = userSetting.Estacion;
        this.IdRol = userSetting.IdRol;
        this.getCiudades();
        this.ciudadSelect = userSetting.CiudadId;
        this.getEstaciones();
        this.estacionSelect = userSetting.Estacion;

        if (this.IdRol == 3 || this.IdRol == 7) {
            this.deshabilitarCombos = true;
        }

    },
    beforeCreate() {

    },
    created() {
        // console.log('created');
    },
    components: {
        DxTotalItem, DxSummary,
        DxDataGrid, DxFormat, DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
    },
    beforeMount() {
        // console.log('beforeMount');
    },
    computed: {
    },
    methods: {
        onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Puntos Canjeados');

            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                autoFilterEnabled: true
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PuntosCanjeados.xlsx');
                });
            });
            e.cancel = true;
        },
        async getCiudades() {
            try {
                const response = await axios.get('Catalogos/GetCiudades');
                if (response.data.Success) {
                    if (response.data.response.length == 0) {
                        this.alertMessage = "No se encontró información.";
                        this.alertTitle = "Error";
                        this.show('top-right', 'error');
                    }
                } else {
                    console.log("error")
                    this.alertMessage = response.data.Error;
                    this.alertTitle = "Error";
                    this.show('top-right', 'error');
                }
                this.ciudades = response.data.response;
            } catch (e) {
                //this.alert = true;
                //this.error = 'Invalid email/password';
            }
        },
        async getEstaciones() {
            try {
                const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
                if (response.data.Success) {
                    if (response.data.response.length == 0) {
                        this.alertMessage = "No se encontró información.";
                        this.alertTitle = "Error";
                        this.show('top-right', 'error');
                    }
                } else {
                    console.log("error")
                    this.alertMessage = response.data.Error;
                    this.alertTitle = "Error";
                    this.show('top-right', 'error');
                }
                this.estaciones = response.data.response;


                // this.productos = [];
                // this.getProductos();
            } catch (e) {
                //this.alert = true;
                //this.error = 'Invalid email/password';
            }
        },
        show(group, type = '') {
            const text = this.alertMessage;
            this.$notify({
                group,
                title: this.alertTitle,
                text,
                type,
                data: {
                    randomNumber: Math.random()
                }
            })
        },
        fechaFormat(fecha) {
            var self = this;
            var d = new Date(fecha);
            var date = self.formatNum(d.getDate()) + '/' + self.formatNum(d.getMonth() + 1) + '/' + d.getFullYear();
            return date.toString();

        },
        formatNum(num) {

            if (num == 1) {
                return '01';
            } else if (num == 2) {
                return '02';
            } else if (num == 3) {
                return '03';
            } else if (num == 4) {
                return '04';
            } else if (num == 5) {
                return '05';
            } else if (num == 6) {
                return '06';
            } else if (num == 7) {
                return '07';
            } else if (num == 8) {
                return '08';
            } else if (num == 9) {
                return '09';
            } else {
                return num;
            }
        },
        async search(e) {
            var self = this;
            e.preventDefault();

            self.searchResult = [];

            self.textoBotonBuscar = "Buscando...";
            self.loading = true;
            try {
                self.intDate = self.date.replace('/', '');
                self.intDate = self.intDate.replace('/', '');


                self.intDateEnd = self.dateEnd.replace('/', '');
                self.intDateEnd = self.intDateEnd.replace('/', '');

                var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
                var estacion = self.estacionSelect != undefined ? self.estacionSelect : 0;
                var gasolinera = 0;
                for (var es = 0; es < self.estaciones.length; es++) {
                    if (estacion == self.estaciones[es].NoEstacion) {
                        gasolinera = self.estaciones[es].Gasolinera;
                        break;
                    }
                }

                var dataToSend = {
                    "Data": {
                        "FechaIni": self.intDate,
                        "FechaFin": self.intDateEnd,
                        "Estacion": estacion,
                        "Ciudad": ciudad
                    }
                };
                const response = await axios.post('Lealtad/GetRedemptions', dataToSend);


                self.loading = false;
                self.textoBotonBuscar = "Buscar";
                if (response.data.Success) {
                    if (response.data.response.length == 0) {
                        self.alertMessage = "No se encontró información del día especificado.";
                        self.alertTitle = "Error";
                        self.show('top-right', 'error');
                    } else {
                        var respuesta = response.data.response;
                        self.searchResult = respuesta;


                        // for (var n = 0; n < self.searchResult.length; n++) {
                        //     self.searchResult[n].FechaString = moment(self.searchResult[n].Fecha).format("DD/MM/YYYY");
                        // }


                    }
                } else {
                    console.log("error")
                    self.alertMessage = response.data.Message;
                    self.alertTitle = "Error";
                    self.show('top-right', 'error');
                }

                self.loading = false;
            } catch (e) {
                self.loading = false;
                //this.alert = true;
                //this.error = 'Invalid email/password';
            }
        },
        async openModalVisor(data) {
            var self = this;

            self.loading = true;

            try {

                var cardInfo = data.row.data;
                var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
                console.log(cardInfo);
                var dataToSend = {
                    "Data": {
                        "Estacion": cardInfo.NoEstacion,
                        "Transaccion": cardInfo.Transaccion,
                        "Ciudad": ciudad
                    }
                }

                const response = await axios.post('Despacho/GetTicketFull', dataToSend);

                this.$bvModal.show('modalDialogVisor');
                if (response.data.Success) {
                    if (response.data.response.length == 0) {
                        self.alertMessage = "No se encontró información especificada.";
                        self.alertTitle = "Error";
                        self.show('top-right', 'error');
                    } else {
                        var respuesta = response.data.response;
                        self.Archivo.base64 = "data:application/pdf;base64," + respuesta.PDF;
                        self.loading = false;
                    }
                } else {
                    console.log("error")
                    self.alertMessage = response.data.Message;
                    self.alertTitle = "Error";
                    self.show('top-right', 'error');
                    self.loading = false;
                }

                self.loading = false;
            } catch (e) {
                self.loading = false;
                self.alertMessage = e;
                self.alertTitle = "Error";
                self.show('top-right', 'error');
            }
        },

    },
    data() {
        return {
            showHeaderFilter: true,
            loading: false,
            deshabilitarCombos: false,
            ciudades: [],
            estaciones: [],
            ciudadSelect: 1,
            estacionSelect: 0,
            date: moment().startOf('month').format("YYYY/MM/DD"),
            intDateEnd: "",
            dateEnd: moment().format("YYYY/MM/DD"),
            intDate: "",
            options: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            pageUserSettings: {},
            alertMessage: "",
            alertTitle: "",
            searchResult: [],
            textoBotonBuscar: "Buscar",
            Archivo: {
                base64: '',
            },
        };
    }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
    display: none !important;
}

.loading-search {
    position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding-top: 150px;
    top: 0;
}
</style>