<template>
  <div>

    <div class=" loading-search" v-if="loading">
      <div class="fa-3x text-center">
        <i class="fas fa-circle-notch fa-spin text-success" style=""></i>
      </div>
    </div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Facturación</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Servicios Administrativos</a></li>
      <li class="breadcrumb-item active"><a href="javascript:;">Reporte</a></li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Facturas <small></small></h1>
    <!-- <button class="btn" v-on:click="redirectTanques()">tanques</button> -->
    <!-- END page-header -->
    <!-- <a href="javascript:;" v-on:click="show('top-right', 'warn')" class="btn btn-sm btn-primary">Demo</a> -->

    <div class="row">
      <div class="col-md-12">

        <div class="row mb-3">
          <!-- <label class="form-label col-form-label col-md-3">Radios {{radioBusqueda}}</label> -->
          <div class="col-md-9 pt-2">
            <div class="form-check mb-2">
              <input class="form-check-input" type="radio" value="filtros" name="flexRadioDefault"
                id="flexRadioDefault1" checked v-model="radioBusqueda">
              <label class="form-check-label" for="flexRadioDefault1">
                Filtros
              </label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="radio" value="ticket" name="flexRadioDefault" id="flexRadioDefault2"
                v-model="radioBusqueda">
              <label class="form-check-label" for="flexRadioDefault2">
                Ticket
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <form @submit.prevent="search">    -->
      <div class="col-md-12" v-if="radioBusqueda == 'filtros'">
        <div class="form-group row">

          <div class="col-md-2">
            <label class="col-form-label">Fecha inicial</label>
            <div class="input-group">
              <date-picker id="dateTimeInput" v-model="date" :config="options"></date-picker>
              <label for="dateTimeInput" class="input-group-text">
                <i class="fa fa-calendar"></i>
              </label>
            </div>
          </div>


          <div class="col-md-2">
            <label class="col-form-label">Fecha final</label>
            <div class="input-group">
              <date-picker id="dateTimeInputEnd" v-model="dateEnd" :config="options"></date-picker>
              <label for="dateTimeInputEnd" class="input-group-text">
                <i class="fa fa-calendar"></i>
              </label>
            </div>
          </div>
          <div class="col-md-2">
            <label class="col-form-label">Ciudad</label>
            <select class="form-select" @change="getEstaciones()" v-model="ciudadSelect" :disabled="deshabilitarCombos">
              <option v-for="ciudad in ciudades" :value="ciudad.Id" v-bind:key="ciudad.Id">
                {{ ciudad.Nombre }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <label class="col-form-label">Estación</label>
            <select class="form-select" v-model="estacionSelect" :disabled="deshabilitarCombos">
              <option value="0">Todas</option>
              <option v-for="estacion in estaciones" :value="estacion.NoEstacion" v-bind:key="estacion.NoEstacion">
                {{ estacion.Nombre }}
              </option>
            </select>
          </div>
          <div class="col-md-1">
            <label class="col-form-label">Estatus</label>
            <select class="form-select" v-model="estatusFacturas">
              <option value="0">Todas</option>
              <option value="1">Vigentes</option>
              <option value="2">Canceladas</option>

            </select>
          </div>
          <div class="col-md-3">


            <!-- <form class="form-horizontal form-bordered"> -->
            <label class="col-form-label">Buscar Cliente</label>

            <div class="input-group">
              <input type="text" class="form-control" v-model="clienteBuscar" />
              <button type="button" class="btn btn-success" @click="getClientes()"
                :disabled="clienteBuscar == ''">Buscar Cliente</button>
            </div>
            <!-- </form> -->
            <div style="margin-top: 10px;" v-if="cliente.RFC != ''">{{ cliente.RFC }} - {{ cliente.Nombre }} <a
                @click="limpiarCampoCliente()"><i class="fa fa-window-close"
                  style="color: red; cursor: pointer; font-size: 1.3rem;"></i></a></div>
            <div class="table-responsive" v-if="clientes.length > 0">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>RFC</th>
                    <th>Nombre</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cliente in clientes" :key="cliente.Id">
                    <td>{{ cliente.RFC }}</td>
                    <td>{{ cliente.Nombre }}</td>
                    <td><button type="button" class="btn btn-success btn-xs"
                        @click="seleccionarCliente(cliente)">Seleccionar</button></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>


          <!-- <div class="col-md-6">
                      <button type="submit" class="btn btn-success me-1 mb-1">{{textoBotonBuscar}}</button>
                    </div> -->
        </div>
      </div>

      <div class="col-md-8" v-if="radioBusqueda == 'ticket'">
        <div class="row">
          <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12">
            <label class="col-form-label" style="    font-size: 12px;">Buscar por ticket (Ingresar el número completo
              del ticket, incluyendo número de estación)</label>
            <div class="input-group">
              <input type="text" class="form-control" v-model="ticketBuscar" />
              <button type="button" class="btn btn-success" @click="buscarPorTicket()">Buscar</button>
            </div>

          </div>
        </div>

      </div>



      <div class="col-md-4 text-center" style="margin-bottom: 15px;" v-if="radioBusqueda == 'filtros'">
      </div>


      <div class="col-md-4 text-center" style="margin-bottom: 15px;" v-if="radioBusqueda == 'filtros'">
        <button type="button" class="btn btn-lg btn-success me-1 mb-1" @click="search" style="margin-top: 3.1em;">{{
          textoBotonBuscar }}</button>
        <!-- <button type="button" @click="descargarZip" class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">descargar</button> -->
      </div>


      <div class="col-md-2 text-end" style="margin-bottom: 15px;">

        <div class="form-check form-switch mb-2" style="width: 180px; text-align: left; margin-top: 3.1em;"
          v-if="permitirSeleccionarTodas && facturas.length > 0 && radioBusqueda == 'filtros'">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="seleccionarTodas" />
          <label class="form-check-label" for="flexSwitchCheckChecked"><span v-if="!seleccionarTodas">Seleccionar
              todas</span><span v-if="seleccionarTodas">Deshacer selección</span></label>
        </div>
      </div>

      <div class="col-md-2 text-end" style="margin-bottom: 15px;">
        <button type="button" @click="descargaMultiple" :disabled="!(contador > 0) && !seleccionarTodas"
          class="btn btn-success me-1 mb-1" style="margin-top: 3.1em;">
          {{ descargarFacturasBtnTxt }}
        </button>
      </div>
      <!-- </form> -->
    </div>

    <div>
      <panel title="Facturas" bodyClass="p-0" class="">
        <!-- <vue-good-table :columns="columns" :rows="facturas" :lineNumbers="false"
          :search-options="{ enabled: true, placeholder: 'Buscar' }"
          :pagination-options="{ enabled: true, position: 'bottom' }">
          <template slot="table-row" slot-scope="props">

            <span v-if="props.column.label == 'Acciones'">
              <button class="btn btn-xs btn-success" @click="descargaUnitaria(props.row)">Descargar</button>
            </span>
            <span v-else-if="props.column.label == 'Descarga'">
              <div class="form-check form-switch mb-2">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                  v-model="props.row.Seleccionado" @click="contadorSeleccionados(props.row)"
                  :disabled="(disableSeleccionar && !props.row.Seleccionado) && !permitirSeleccionarTodas" />

              </div>
            </span>

          </template>
</vue-good-table> -->


        <DxDataGrid id="gridContainer" :data-source="facturas" key-expr="Id" :show-borders="true"
          :show-column-lines="true" :show-sumary="true" :show-row-lines="true" width="100%" :column-auto-width="true">

          <DxScrolling column-rendering-mode="virtual" />
          <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 20, 50, 100]"
            :show-navigation-buttons="true" />
          <DxPaging :page-size="10" :page-index="1" />
          <DxFilterRow />
          <DxHeaderFilter :visible="showHeaderFilter" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Search..." />

          <DxGrouping :auto-expand-all="true" />
          <DxExport :enabled="true" :allow-export-selected-data="false" />
          <DxSelection mode="false" />

          <DxColumn data-field="Id" caption="Id" :visible="false">
          </DxColumn>

          <DxColumn data-field="Estacion" caption="Estacion">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <DxColumn data-field="EstacionNombre" caption="Nombre Estación">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <DxColumn data-field="Folio" caption="Folio">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <DxColumn data-field="Serie" caption="Serie">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <DxColumn data-field="FechaFacturaString" caption="Fecha Factura" dataType="datetime"
            format="dd/MM/yyyy h:mm a">
          </DxColumn>
          <DxColumn data-field="FechaVentaString" caption="Fecha Venta" dataType="datetime" format="dd/MM/yyyy h:mm a">
          </DxColumn>
          <DxColumn data-field="Despacho" caption="Despacho">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <DxColumn data-field="Estatus" caption="Estatus" cell-template="grid-cell-status">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <template #grid-cell-status="{ data }">
            <div class="text-center">
              <div class="row">
                <div class="col">
                  <i v-if="data.row.data.Estatus == '1'" class="far fa fa-check-circle fa-2x text-green"></i>
                  <i v-if="data.row.data.Estatus == '2'" class="far fa-lg fa-fw fa fa-ban fa-2x text-danger"></i>
                  <i v-if="data.row.data.Estatus == '3'" class="far fa fa-exclamation-circle fa-2x text-warning"></i>
                </div>
              </div>
            </div>
          </template>


          <DxColumn data-field="Total" caption="Total">
            <DxFormat type="currency" :precision="2" />
          </DxColumn>
          <DxColumn data-field="UUID" caption="UUID">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <DxColumn data-field="RFCEmisor" caption="RFC Emisor">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <DxColumn data-field="RFCReceptor" caption="RFC Receptor">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>
          <DxColumn data-field="NombreReceptor" caption="Nombre Receptor">
            <DxHeaderFilter :allow-search="true" />
          </DxColumn>

          <DxColumn dataField="Acciones" caption="Acciones" cell-template="grid-cell-template" :width="255"
            :fixed="true" fixed-position="right" />

          <template #grid-cell-template="{ data }">
            <div class="text-center">
              <div class="row">
                <div class="col">
                  <button class="btn btn-xs btn-success" @click="descargaUnitaria(data.row.data)">Descargar</button>
                </div>
                <div class="col" v-if="data.row.data.RFCReceptor != 'XAXX010101000'">
                  <button class="btn btn-xs btn-danger" @click="modalCancelar(data.row.data)">Cancelar</button>
                </div>
                <!-- <div class="col" v-if="data.row.data.RFCReceptor != 'XAXX010101000'">
                  <button class=" btn btn-xs btn-info" @click="modalRefacturar(data.row.data)">Refacturar</button>
                </div> -->
              </div>
            </div>
          </template>

          <DxColumn dataField="Descarga" caption="Descarga" cell-template="grid-cell-selected" :width="100"
            :fixed="true" fixed-position="right" />

          <template #grid-cell-selected="{ data }">
            <div class="text-center">
              <div class="row">
                <div class="col">
                  <div class="form-check form-switch mb-2">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                      v-model="data.row.data.Seleccionado" @click="contadorSeleccionados(data.row.data)"
                      :disabled="(disableSeleccionar && !data.row.data.Seleccionado) && !permitirSeleccionarTodas" />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <DxSummary>
            <DxTotalItem>
            </DxTotalItem>
          </DxSummary>

        </DxDataGrid>

        <b-modal id="modalCancelar" size="l" centered cancel-variant="default">
          <template slot="modal-header">
            <h4 class="modal-title">Cancelar Factura</h4>
            <a class="btn-close" @click="$bvModal.hide('modalCancelar')"></a>
          </template>

          <div class="row">
            <div class="col sm-4">
              <label class="col-form-label">Motivo</label>
              <select class="form-select" v-model="motivoSelected">
                <option v-for="motivo in motivosCancela" :value="motivo" v-bind:key="motivo">
                  {{ motivo.Motivo }}
                </option>
              </select>
            </div>
            <div class="col sm-4">
              <label class="col-form-label">Comentarios</label>
              <input class="form-control" type="text" v-model="cancelarFactura.Comentarios">
            </div>
          </div>

          <template #modal-footer>
            <div class="row">
              <div class="col">
                <b-button class="float-right" variant="primary" @click="Cancelar">
                  Guardar
                </b-button>
              </div>
              <div class="col">
                <b-button class="float-right" variant="secondary" @click="$bvModal.hide('modalCancelar')">
                  Close
                </b-button>
              </div>
            </div>
          </template>

        </b-modal>

        <b-modal id="modalRefacturar" size="xl" centered cancel-variant="default" v-b-modal.modal-scrollable>
          <template slot="modal-header">
            <h4 class="modal-title">Refacturar</h4>
            <a class="btn-close" @click="$bvModal.hide('modalRefacturar')"></a>
          </template>

          <div class="row">
            <div class="col sm-4">
              <label class="col-form-label">Emisor :</label>
              {{ datosRefacturar.RFCEmisor }}
            </div>
            <div class="col sm-4">
              <label class="col-form-label">Motivo</label>
              <select class="form-select" v-model="motivoSelected">
                <option v-for="motivo in motivosCancela" :value="motivo.Id" v-bind:key="motivo.Id">
                  {{ motivo.Motivo }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col col-sm-6">
              <label class="col-form-label">RFC:</label>
              <input class="form-control" type="text" v-model="clienteRefacturar.RFC">
            </div>
          </div>
          <div class="row">
            <div class="col col-sm-6">
              <label class="col-form-label">Nombre:</label>
              <input class="form-control" type="text" v-model="clienteRefacturar.Nombre">
            </div>
          </div>
          <div class="row">
            <div class="col col-sm-6">
              <label class="col-form-label">Codigo Postal:</label>
              <input class="form-control" type="text" v-model="clienteRefacturar.CodigoPostal">
            </div>
          </div>
          <div class="row">
            <div class="col col-sm-6">
              <label class="col-form-label">Uso CFDI:</label>
              <select class="form-select" v-model="clienteRefacturar.UsoCFDI">
                <option v-for="uso in getUsosCFDI" :value="uso.Id" v-bind:key="uso.Id">
                  {{ uso.Descripcion }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col col-sm-6">
              <label class="col-form-label">Regimen Fiscal:</label>
              <select class="form-select" v-model="clienteRefacturar.RegimenFiscal">
                <option v-for="regimen in getRegimenFiscales" :value="regimen.Clave" v-bind:key="regimen.Clave">
                  {{ regimen.Denominacion }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col col-sm-6">

              <div class="col col-sm-6">
                <label class="col-form-label">Correo:</label>
                <input class="form-control" type="text" v-model="clienteRefacturar.Correos">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              Conceptos
            </div>
          </div>
          <div class="row" style="overflow-y: auto;">
            <div class="col">

              <table class="table">
                <thead>
                  <tr>
                    <th>Transacción</th>
                    <th>RFC</th>
                    <th>Nombre</th>
                    <th>Uso CFDI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>63535620</td>
                    <td>GAMJ8905101Q5</td>
                    <td>JUAN CARLOS GARCIA MACIAS</td>
                    <td>G03</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-4">
              <label class="col-form-label">Forma de Pago:</label>
              <select class="form-select" v-model="fomasPagoSelected">
                <option v-for="forma in getFormasPago" :value="forma.ClaveSAT" v-bind:key="forma.ClaveSAT">
                  {{ forma.Descripcion }}
                </option>
              </select>
            </div>
          </div>

          <template #modal-footer>
            <div class="row">
              <div class="col">
                <b-button class="float-right" variant="primary" @click="Refacturar">
                  Guardar
                </b-button>
              </div>
              <div class="col">
                <b-button class="float-right" variant="secondary" @click="$bvModal.hide('modalRefacturar')">
                  Close
                </b-button>
              </div>
            </div>
          </template>

        </b-modal>


      </panel>
    </div>
    <!-- bottom left vue-notification -->
    <notifications group="top-right" position="top right" :speed="1000" />
  </div>
</template>

<script>

import {
  DxDataGrid, DxFormat, DxTotalItem, DxSummary,
  DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
} from 'devextreme-vue/data-grid';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import axios from 'axios';
import moment from 'moment';

import 'ionicons';



export default {
  mounted: function () {
    // eslint-disable-line no-debugger
    var userSetting = JSON.parse(localStorage.getItem('userSettings'));
    this.usuario = userSetting.Usuario;
    this.usuarioEstacion = userSetting.Estacion;
    this.IdRol = userSetting.IdRol;
    this.getCiudades();
    this.ciudadSelect = userSetting.CiudadId;
    this.getEstaciones();
    this.estacionSelect = userSetting.Estacion;

    if (this.IdRol == 3 || this.IdRol == 7) {
      this.deshabilitarCombos = true;
    }
  },
  beforeCreate() {
  },
  created() {
    // console.log('created');
  },
  beforeMount() {
    // console.log('beforeMount');
  },
  components: {
    DxTotalItem, DxSummary,
    DxDataGrid, DxFormat, DxPaging, DxPager, DxScrolling, DxColumn, DxExport, DxSelection, DxGrouping, DxHeaderFilter, DxFilterRow, DxSearchPanel,
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Facturas');

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Facturas.xlsx');
        });
      });
      e.cancel = true;
    },
    descargaUnitaria(factura) {
      var self = this;

      // eslint-disable-line no-debugger

      var selectedDate = moment(factura.FechaFactura).format('YYYYMMDD');
      var today = moment();

      // var difference = today.diff(selectedDate, 'months');
      // console.log('Difference', difference);

      var diferenciaDecimal = today.diff(selectedDate, 'months', true);
      if (diferenciaDecimal < 3) {
        self.descargaUnitariaReciente(factura)
      } else {
        var filesArray = [factura.XmlPath];
        self.descargarAntiguas(filesArray);
      }
    },

    async descargarAntiguas(filesArray) {
      // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      self.descargarFacturasBtnTxt = "Descargando...";
      const bodyData = {
        "Data": {
          "Usuario": self.usuario,
          "Files": filesArray
        }
      };
      try {
        const response = await axios.post("BlobStorage/GetZipFiles", bodyData);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            // eslint-disable-line no-debugger
            // if(response.data.response.CfdiNoFound.length > 0){
            //   this.alertMessage = "No se encontraron algunas facturas: "+response.data.response.CfdiNoFound;
            //   this.alertTitle = "Error";
            //   this.show("top-right", "info");
            // }
            self.descargarZip(response.data.response.zip);
            // self.descargarXML(response.data.response.XML);
            // self.descargarPDF(response.data.response.PDF);
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Facturas";
        }
        // this.textoBotonBuscar = "Buscar Facturas";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
        self.loading = false;
        self.descargarFacturasBtnTxt = "Descargar Facturas";
      }
    },
    async descargaUnitariaReciente(factura) {
      var self = this;
      self.loading = true;
      self.descargarFacturasBtnTxt = "Descargando...";
      try {
        const response = await axios.post("CFDI/GetArchivosCFDI?UUID=" + factura.UUID, {});
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            // eslint-disable-line no-debugger
            self.descargarXML(response.data.response.XML);
            self.descargarPDF(response.data.response.PDF);
            self.loading = false;
            self.descargarFacturasBtnTxt = "Descargar Factura";
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Factura";
        }
        // this.textoBotonBuscar = "Buscar Facturas";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
        self.loading = false;
        self.descargarFacturasBtnTxt = "Descargar Factura";
      }
    },
    descargarXML(xmlString) {
      // eslint-disable-line no-debugger

      var xmltext = xmlString;

      var filename = "Factura_Electronica.xml";
      var pom = document.createElement('a');
      var bb = new Blob([xmltext], { type: 'text/plain' });

      pom.setAttribute('href', window.URL.createObjectURL(bb));
      pom.setAttribute('download', filename);

      pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
      pom.draggable = true;
      pom.classList.add('dragout');

      pom.click();
      //document.body.removeChild(pom);
    },
    mostrarAlerta(val) {
      alert(val);
    },
    descargarPDF(pdfBase64) {
      // eslint-disable-line no-debugger

      var filename = "Factura_Electronica.pdf";
      // The actual download
      var link = document.createElement('a');
      //link.href = window.URL.createObjectURL(blob);
      link.href = "data:application/pdf;base64," + pdfBase64;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    contadorSeleccionados(row) {
      const self = this;
      //var val = row.Seleccionado;

      const index = self.facturas.findIndex(factura => factura.Id === row.Id);

      if (index !== -1) {
        // Invierte el estado de la propiedad Seleccionado
        self.facturas[index].Seleccionado = !row.Seleccionado;

        // Actualiza el contador
        if (self.facturas[index].Seleccionado) {
          self.contador++;
        } else {
          self.contador--;
        }

        // Habilita o deshabilita la selección según el contador
        self.disableSeleccionar = self.contador > 9;
      } else {
        console.error("Fila no encontrada en el arreglo.");
      }

      // eslint-disable-line no-debugger
      // var self = this
      // self.facturas[row.originalIndex].Seleccionado = !val;
      // if (!val) {
      //   self.contador++;
      // } else {
      //   self.contador--;
      // }



      // if (self.contador > 9) {
      //   self.disableSeleccionar = true;
      // } else {
      //   self.disableSeleccionar = false;
      // }
    },
    limpiarCampoCliente() {
      var self = this;

      setTimeout(function () {
        self.clientes = [];
        self.cliente.RFC = "";
        self.cliente.Nombre = "";
      }, 0);

    },
    descargaMultiple() {
      // eslint-disable-line no-debugger
      var self = this;
      var selectedDate = moment(self.date).format('YYYYMMDD');
      var today = moment();
      var diferenciaDecimal = today.diff(selectedDate, 'months', true);


      if (diferenciaDecimal < 3) {
        var UUIDsArray = [];
        for (var n = 0; n < self.facturas.length; n++) {
          if (self.facturas[n].Seleccionado) {
            UUIDsArray.push(self.facturas[n].UUID);
          }
        }
        self.descargaMultipleReciente(UUIDsArray);
      } else {

        var filesArray = [];
        for (var i = 0; i < self.facturas.length; i++) {
          if (self.facturas[i].Seleccionado) {
            filesArray.push(self.facturas[i].XmlPath);
          }
        }
        self.descargarAntiguas(filesArray);
      }
    },
    async descargaMultipleReciente(UUIDsArray) {
      // eslint-disable-line no-debugger
      var self = this;
      self.loading = true;
      self.descargarFacturasBtnTxt = "Descargando...";
      const bodyData = {
        "Data": {
          "Usuario": self.usuario,
          "Files": UUIDsArray
        }
      };
      try {
        const response = await axios.post("CFDI/GetZipFiles", bodyData);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            // eslint-disable-line no-debugger
            if (response.data.response.CfdiNoFound.length > 0) {
              this.alertMessage = "No se encontraron algunas facturas: " + response.data.response.CfdiNoFound;
              this.alertTitle = "Error";
              this.show("top-right", "info");
            }
            self.descargarZip(response.data.response.zip);
            // self.descargarXML(response.data.response.XML);
            // self.descargarPDF(response.data.response.PDF);
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
          self.loading = false;
          self.descargarFacturasBtnTxt = "Descargar Facturas";
        }
        // this.textoBotonBuscar = "Buscar Facturas";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
        self.loading = false;
        self.descargarFacturasBtnTxt = "Descargar Facturas";
      }
    },
    descargarZip(base64Zip) {
      var self = this;


      var filename = "facturas.zip";//nombreArchivo;
      var link = document.createElement("a");
      link.href = "data:application/zip;base64," + base64Zip;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      self.loading = false;
      self.descargarFacturasBtnTxt = "Descargar Facturas";
    },
    async seleccionarCliente(cliente) {
      var self = this;
      try {
        const response = await axios.post("Cliente/GetCliente", {
          Data: {
            RFC: cliente.RFC,
            Id: 0,
            Tarjeta: "",
          },
        });
        if (response.data.Success) {
          if (response.data.response == null) {
            this.alertMessage =
              "No se encontró información del cliente especificado.";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {

            // eslint-disable-line no-debugger
            self.cliente = response.data.response;
            self.clientes = [];
            self.clienteBuscar = "";
            if (response.data.response.Correos.length > 0) {
              self.clienteCorreo = response.data.response.Correos[0].Direccion;
            }
            return;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },
    async getClientes() {
      this.textoBotonBuscar = "Buscando Facturas...";
      try {
        const response = await axios.post("Cliente/GetClientes", {
          Data: {
            Busqueda: this.clienteBuscar,
            IncluirCancelados: 1,
          },
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage =
              "No se encontró información del cliente especificado.";
            this.alertTitle = "Error";
            this.show("top-right", "error");
          } else {
            this.clientes = response.data.response;
          }
        } else {
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show("top-right", "error");
        }
        this.textoBotonBuscar = "Buscar Facturas";
      } catch (e) {
        this.alertMessage = "Ocurrio un error";
        this.alertTitle = "Error";
        this.show("top-right", "error");
      }
    },

    async getCiudades() {
      try {
        const response = await axios.get('Catalogos/GetCiudades');
        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.ciudades = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getEstaciones() {
      try {
        // eslint-disable-line no-debugger
        const response = await axios.post('Estacion/GetEstaciones?ciudad=' + this.ciudadSelect);
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.estaciones = response.data.response;
        this.productos = [];
        this.getProductos();
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    redirectTanques() {

      this.$router.push({ path: '/tanques' });
    },
    show(group, type = '') {
      const text = this.alertMessage;
      this.$notify({
        group,
        title: this.alertTitle,
        text,
        type,
        data: {
          randomNumber: Math.random()
        }
      })
    },
    clean(group) {
      this.$notify({ group, clean: true })
    },
    swalNotification(swalType) {
      var btnClass = (swalType == 'error') ? 'danger' : swalType;
      btnClass = (swalType == 'question') ? 'primary' : btnClass;
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: swalType.charAt(0).toUpperCase() + swalType.substr(1).toLowerCase(),
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn me-5px btn-' + btnClass + '',
        cancelButtonClass: 'btn btn-default',
      })
    },
    async buscarPorTicket() {
      var self = this;
      // e.preventDefault();

      self.textoBotonBuscar = "Buscando Facturas...";
      self.loading = true;
      try {


        // eslint-disable-line no-debugger
        //alert(self.estatusFacturas);
        const response = await axios.post('CFDI/GetFacturasTicket', {
          "Data": {
            "Ticket": self.ticketBuscar,
          }
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            self.alertMessage = "No se encontro información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }
        } else {
          console.log("error")
          self.alertMessage = response.data.Error;
          self.alertTitle = "Error";
          self.show('top-right', 'error');
        }

        self.contador = 0;
        self.disableSeleccionar = false;
        for (var n = 0; n < response.data.response.length; n++) {
          response.data.response[n].Seleccionado = false;
          response.data.response[n].FechaVentaString = moment(response.data.response[n].FechaVenta).format("DD/MM/YYYY h:mm a");
          response.data.response[n].FechaFacturaString = moment(response.data.response[n].FechaFactura).format("DD/MM/YYYY h:mm a");
        }

        self.facturas = response.data.response;


        self.textoBotonBuscar = "Buscar Facturas";

        self.loading = false;
      } catch (e) {
        self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async search() {
      var self = this;
      // e.preventDefault();

      if (moment(self.date).format('MM') != moment(self.dateEnd).format('MM')) {

        self.alertMessage = "Las fechas deben corresponder al mismo mes."
        self.alertTitle = "Error";
        self.show('top-right', 'error');
        return;
      }
      self.textoBotonBuscar = "Buscando Facturas...";
      self.loading = true;
      try {

        self.intDate = self.date.replace('/', '');
        self.intDate = self.intDate.replace('/', '');


        self.intDateEnd = self.dateEnd.replace('/', '');
        self.intDateEnd = self.intDateEnd.replace('/', '');

        var ciudad = self.ciudadSelect != undefined ? self.ciudadSelect : 0;
        var estacion = self.estacionSelect != undefined ? self.estacionSelect : 0;
        if (self.date != self.dateEnd || estacion == 0) {
          self.permitirSeleccionarTodas = false;
          self.seleccionarTodas = false;
        } else {
          self.permitirSeleccionarTodas = true;
          self.seleccionarTodas = false;
        }
        // eslint-disable-line no-debugger
        //alert(self.estatusFacturas);
        const response = await axios.post('CFDI/GetFacturas', {
          "Data": {
            "FechaInicial": self.intDate,
            "FechaFinal": self.intDateEnd,
            "RFC": self.cliente.RFC,
            "Ciudad": ciudad,
            "Estacion": estacion
          }
        });
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            self.alertMessage = "No se encontro información del día especificado.";
            self.alertTitle = "Error";
            self.show('top-right', 'error');
          }
        } else {
          console.log("error")
          self.alertMessage = response.data.Error;
          self.alertTitle = "Error";
          self.show('top-right', 'error');
        }

        self.contador = 0;
        self.disableSeleccionar = false;
        for (var n = 0; n < response.data.response.length; n++) {
          response.data.response[n].Seleccionado = false;
          response.data.response[n].FechaVentaString = moment(response.data.response[n].FechaVenta).format("DD/MM/YYYY h:mm a");
          response.data.response[n].FechaFacturaString = moment(response.data.response[n].FechaFactura).format("DD/MM/YYYY h:mm a");
        }

        self.facturas = response.data.response;

        self.textoBotonBuscar = "Buscar Facturas";

        self.loading = false;
      } catch (e) {
        self.loading = false;
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async getMotivosCancelacion(motivo) {

      try {

        const response = await axios.post('Catalogos/GetMotivosCancelacion', {
          Data: {
            ClaveSAT: motivo
          }
        });

        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.motivosCancela = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    modalCancelar(data) {
      this.$bvModal.show('modalCancelar');
      console.log(data);
      this.cancelarFactura = data;
      this.getMotivosCancelacion("02");
    },
    async Cancelar() {
      var self = this;

      try {

        const response = await axios.post('CFDI40/Cancelar', {
          Data: {
            Usuario: self.usuario,
            UUIDCancelar: self.cancelarFactura.UUID,
            UUIDSustituye: '',
            IdMotivo: self.motivoSelected.IdMotivo,
            ClaveSAT: self.motivoSelected.ClaveSAT,
            Motivo: self.motivoSelected.Motivo,
            Comentarios: self.cancelarFactura.Comentarios,
          }
        });

        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.motivosCancela = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }

    },
    modalRefacturar(data) {
      this.$bvModal.show('modalRefacturar');
      console.log(data);
      this.datosRefacturar = data;
      this.getMotivosCancelacion("");
      this.cargarCliente(data.RFCReceptor);
      this.GetUsosCFDI();
      this.GetFormasPago();
      this.GetRegimenFiscales();

    },
    async cargarCliente(rfc) {
      var self = this;
      try {
        const response = await axios.post('Cliente/GetCliente', {
          Data: {
            rfc: rfc
          }
        });

        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.clienteRefacturar = response.data.response;

        if (this.clienteRefacturar.Correos.length != 0) {

          this.clienteRefacturar.Correos = this.clienteRefacturar.Correos[0].Direccion;
        }


      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async Refacturar() {
      var self = this;

      try {

        const response = await axios.post('Cliente/GetCliente', {
          Data: {
            Transaccion: 63535620,
            RFC: "GAMJ8905101Q5",
            Nombre: "JUAN CARLOS GARCIA MACIAS",
            UsoCFDI: "G03",
            Correo: "JUANCARLOS.JCGM89@GMAIL.COM",
            FormaPago: "01",
            Usuario: "juan.garcia",
            DomicilioFiscalReceptor: "22245",
            RegimenFiscalReceptor: "626",
            UUID: "401A8E65-4B95-498B-9576-AADD08D4DE4E",
            IdMotivo: 2,
            ClaveSAT: "01",
            Motivo: "Forma de pago erronea",
            Comentarios: "Factura de prueba"
          }

        });

        //debugger; // eslint-disable-line no-debugger
        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.motivosCancela = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async GetUsosCFDI() {
      var self = this;
      try {
        const response = await axios.get('Catalogos/GetUsosCFDI');

        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.getUsosCFDI = response.data.response;
      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async GetFormasPago() {
      var self = this;
      try {
        const response = await axios.get('Catalogos/GetFormasPago?aplicaEstacion=1');

        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.getFormasPago = response.data.response;

      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
    async GetRegimenFiscales() {
      var self = this;
      try {
        const response = await axios.get('Catalogos/GetRegimenFiscales');

        if (response.data.Success) {
          if (response.data.response.length == 0) {
            this.alertMessage = "No se encontró información.";
            this.alertTitle = "Error";
            this.show('top-right', 'error');
          }
        } else {
          console.log("error")
          this.alertMessage = response.data.Error;
          this.alertTitle = "Error";
          this.show('top-right', 'error');
        }
        this.getRegimenFiscales = response.data.response;

      } catch (e) {
        //this.alert = true;
        //this.error = 'Invalid email/password';
      }
    },
  },
  watch: {
    radioBusqueda() {
      var self = this;
      var hide = true;
      if (self.radioBusqueda == 'ticket') {
        hide = false;
      }
      for (var n = 0; n < self.columns.length; n++) {
        if (self.columns[n].label == 'Despacho') {
          self.columns[n].hidden = hide;
        }
      }

    },
    seleccionarTodas(val) {
      // eslint-disable-line no-debugger
      var self = this;
      for (var n = 0; n < self.facturas.length; n++) {
        self.facturas[n].Seleccionado = val;
      }
      self.contador = 0;
    },
  },
  data() {
    return {
      ticketBuscar: '',
      radioBusqueda: 'filtros',
      estatusFacturas: 0,
      descargarFacturasBtnTxt: 'Descargar Facturas',
      contador: 0,
      permitirSeleccionarTodas: true,
      seleccionarTodas: false,
      base64Zip: "",
      clienteBuscar: "",
      clientes: [],
      cliente: {
        RFC: ''
      },

      loading: false,
      showHeaderFilter: true,
      deshabilitarCombos: false,
      ciudades: [],
      estaciones: [],
      ciudadSelect: 1,
      estacionSelect: 0,
      date: moment().startOf('month').format("YYYY/MM/DD"),
      intDateEnd: "",
      dateEnd: moment().format("YYYY/MM/DD"),
      intDate: "",
      options: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      pageUserSettings: {},
      alertMessage: "",
      alertTitle: "",
      textoBotonBuscar: "Buscar Facturas",
      cancelarFactura: {
        Usuario: '',
        UUIDCancelar: '',
        UUIDSustituye: '',
        IdMotivo: '',
        ClaveSAT: '',
        Motivo: '',
        Comentarios: '',
      },
      motivoSelected: '',
      motivosCancela: [],
      getUsosCFDI: [],
      usoCFDISelected: '',
      getFormasPago: [],
      fomasPagoSelected: '',
      getRegimenFiscales: [],
      regimenSelected: '',
      clienteRefacturar: {
        CodigoPostal: '',
        Correos: [],
        Estatus: false,
        Id: 0,
        Nombre: '',
        RFC: '',
        RegimenFiscal: '',
        UsoCFDI: '',
      },
      datosRefacturar: [],

      // columns: [
      //   // {
      //   //   label: "Id",
      //   //   field: "Id",
      //   //   tdClass: "text-center text-nowrap",
      //   //   thClass: "text-center text-nowrap",
      //   // },
      //   {
      //     label: "Estación",
      //     field: "Estacion",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "Nombre Estación",
      //     field: "EstacionNombre",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "Folio",
      //     field: "Folio",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "Serie",
      //     field: "Serie",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "Fecha Factura",
      //     field: "FechaFacturaString",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "Fecha Venta",
      //     field: "FechaVentaString",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "Despacho",
      //     field: "Despacho",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //     hidden: false
      //   },
      //   {
      //     label: "Estatus",
      //     field: "Estatus",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "Total",
      //     field: "Total",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "UUID",
      //     field: "UUID",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "RFC Emisor",
      //     field: "RFCEmisor",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "RFC Receptor",
      //     field: "RFCReceptor",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "Nombre Receptor",
      //     field: "NombreReceptor",
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   // {
      //   //   XmlPath: "Nombre Receptor",
      //   //   XmlPath: "NombreReceptor",
      //   //   tdClass: "text-center text-nowrap",
      //   //   thClass: "text-center text-nowrap",
      //   // },


      //   {
      //     label: "Acciones",
      //     field: "Acciones",
      //     width: '20%',
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   },
      //   {
      //     label: "Descarga",
      //     field: "Descarga",
      //     width: '10%',
      //     tdClass: "text-center text-nowrap",
      //     thClass: "text-center text-nowrap",
      //   }
      // ],
      facturas: [],
    };
  }
}
</script>
<style scoped>
.dx-datagrid-header-panel {
  display: none !important;
}

.loading-search {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-top: 150px;
  top: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
</style>